import "./RmsDataPage.css";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RestService from "services/RestService";
import { useState } from "react";
import {format} from 'date-fns';

const formatDate = (date: Date) => {
  return format(date, 'yyyyMMdd-HHmm');
}

const isProd = () => {
  const prodUrl = "https://axis.tirebuyer.com";
  return window.location.origin === prodUrl;
}

function RmsDataPage() {
  const [isDownloading, setIsDownloading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState("");
  const [info, setInfo] = useState("");
  const env = isProd() ? "PROD" : "UAT";
  const downloadRmsData = () => {
    setError("");
    const token = RestService.oauthAccessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const url = "/api/installer-service/management/installers/export/xlsx";
    const options = { headers ,
      signal: AbortSignal.timeout(300000)};
    fetch(url, options)
      .then((res) => {
        return res.blob();
      })
      .then((blob) => {
        setIsDownloading(false);
        var file = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = file;
        link.download = `RMS-${env}-Export-${formatDate(new Date())}.xlsx`;

        document.body.appendChild(link);

        link.click();

        link.parentNode?.removeChild(link);
      })
      .catch((err) => {
        console.log(err);
        setError(err);
        setIsDownloading(false);
      });
    setIsDownloading(true);
  };

  const uploadRmsData = (e: any) => {
    setError("");
    const file = e.target.files[0];
    // ensure file is xlsx
    if (!file.name.endsWith(".xlsx")) {
      setError("File must be a xlsx file");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);

    const token = RestService.oauthAccessToken;
    const headers = {
      Authorization: `Bearer ${token}`,
      ContentType: "multipart/form-data",
    };
    const url = "/api/installer-service/management/installers/upload/xlsx";

    const options = { headers, method: "POST", body: formData };
    fetch(url, options)
      .then(async (res) => {
        console.log(res);

        if (res.status >= 400) {
          console.log(res);
          setError("Error uploading file" + await res.text());
          return;
        }
        setInfo("File uploaded successfully");
        setIsUploading(false);
      })
      .catch((err) => {
        setError(err);
        console.log(err);
        setIsUploading(false);
      });

    setIsUploading(true);
  };

  return (
    <div className="wrapper">
      <div className="title-section">
        <h1>RMS Data</h1>
        <div>
          {isDownloading && (
            <span>Downloading... it might take a minute or two... &nbsp;</span>
          )}
          <Button
            disabled={isDownloading}
            className="download-btn"
            variant="contained"
            onClick={downloadRmsData}
          >
            Download RMS Data
          </Button>
        </div>
      </div>
      <div className="upload-section">
        <div className="error">{error && <span>{error}</span>}</div>
        <div>{info && <span>{info}</span>}</div>
        <h2>Upload RMS Data</h2>
        <div className="upload-btn">
          <Button
            disabled={isUploading}
            className="upload-btn"
            component="label"
            variant="contained"
            startIcon={<CloudUploadIcon />}
          >
            Upload XLSX
            <input type="file" hidden onChange={uploadRmsData} accept=".xlsx" />
          </Button>
        </div>
        <p>Upload the updated RMS data XLSX</p>
      </div>
    </div>
  );
}

export default RmsDataPage;
